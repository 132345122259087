import { listAllAssetsAcrossOrganization } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllAssetsAcrossOrganization = {
  data() {
    return {
      overlay: false,
      GetAllAssetsAcrossOrganizationList: [],
    };
  },
  methods: {
    async GetAllAssetsAcrossOrganizationMethod(organization_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listAllAssetsAcrossOrganization, {
            input: {
              organization_id: organization_id,
            },
          })
        );
        this.GetAllAssetsAcrossOrganizationList = JSON.parse(result.data.ListAllAssetsAcrossOrganization).data.items;
        this.overlay = false;
      } catch (error) {
        this.GetAllAssetsAcrossOrganizationList = []
        this.noDataText = error.errors[0].message;
        this.overlay = false;
      }
    },
  },
};
