import { listDivisions } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllDivisionsBasedOnOrganization = {
  data() {
    return {
      overlay: false,
      GetAllDivisionsBasedOnOrganizationList: [],
    };
  },
  methods: {
    async GetAllDivisionsBasedOnOrganizationMethod(organization_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listDivisions, {
            input: {
              organization_id: organization_id,
            },
          })
        );
        this.GetAllDivisionsBasedOnOrganizationList = JSON.parse(result.data.ListDivisions).data.items;
        this.overlay = false;
      } catch (error) {
        this.GetAllDivisionsBasedOnOrganizationList = []
        this.noDataText = error.errors[0].message;
        this.overlay = false;
      }
    },
  },
};
