<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateDivisionsAssetDevicesDialog
      :StoreObj="StoreObj"
      :current_view="current_view"
      :createDivisionAssetDevice="createDivisionAssetDevice"
      :StoreAllCommonObject="StoreAllCommonObject"
      :dialogCreateAssetDivisionAssetDevice="dialogCreateAssetDivisionAssetDevice"
      @clicked="CreateDivisionsAssetDevicesDialogEmit"
    />
    <ActivateInactivateAssetDialog
      :current_view="current_view"
      :StoreAllCommonObject="StoreAllCommonObject"
      :dialogActivateInactivateAsset="dialogActivateInactivateAsset"
      @clicked="dialogActivateInactivateAssetEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Assets</div>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="3">
            <v-card :height="height" class="overflow-y-auto">
              <v-toolbar dense class="elevation-0">
                <v-toolbar-title>Organizations</v-toolbar-title>
              </v-toolbar>
              <v-divider></v-divider>
              <v-list dense class="py-0 my-0">
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item v-for="(item, i) in GetAllOrganizationList" :key="i" @click="checkItem(item)">
                    <v-list-item-icon class="pr-0 mr-1">
                      <v-icon small v-text="'mdi-diamond-stone'"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="pa-0 ma-0">
                      <v-list-item-title v-text="item.organization_name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="9" class="pl-3">
            <v-card height="100%">
              <v-toolbar dense class="elevation-0">
                <v-toolbar-title>{{ current_view }} - ({{ StoreObj.organization_name }})</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field dense outlined class="field_height field_label_size FontSize mt-8 mr-2 maxWidthLarge" v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>
                <v-btn v-if="current_view != 'Asset'" small color="primary" @click="checkRowItem(StoreAllCommonObject, 'BACK')" class="mr-2"><v-icon>mdi-chevron-double-left</v-icon>Back</v-btn>
                <v-btn small color="primary" @click="(dialogCreateAssetDivisionAssetDevice = true), (StoreAllCommonObject['action'] = 'CREATE')">Create</v-btn>
              </v-toolbar>
              <v-divider></v-divider>
              <v-data-table
                dense
                class="elevation-0"
                :search="search"
                :headers="current_view == 'Asset' ? AssetHeaders : DeviceHeaders"
                :items="current_view == 'Asset' ? GetAllAssetsAcrossOrganizationList : GetAllDevicesBasedOnOrganizationList"
                @click:row="checkRowItem"
                :no-data-text="noDataText"
              >
                <template v-slot:[`item.asset_name`]="{ item }">
                  <div class="FontSize">{{ item.asset_name }}</div>
                </template>
                <template v-slot:[`item.division_name`]="{ item }">
                  <div class="FontSize">{{ item.division_name }}</div>
                </template>
                <template v-slot:[`item.device_name`]="{ item }">
                  <div class="FontSize">{{ item.device_name }}</div>
                </template>
                <template v-slot:[`item.asset_created_on`]="{ item }">
                  <div class="FontSize">{{ new Date(item.asset_created_on).toLocaleString() }}</div>
                </template>
                <template v-slot:[`item.division_created_on`]="{ item }">
                  <div class="FontSize">{{ new Date(item.division_created_on).toLocaleString() }}</div>
                </template>
                <template v-slot:[`item.device_created_on`]="{ item }">
                  <div class="FontSize">{{ new Date(item.device_created_on).toLocaleString() }}</div>
                </template>
                <template v-slot:[`item.division_status`]="{ item }">
                  <div class="FontSize" :class="item.division_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'">
                    {{ item.division_status }}
                  </div>
                </template>
                <template v-slot:[`item.asset_status`]="{ item }">
                  <div class="FontSize" :class="item.asset_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'">
                    {{ item.asset_status }}
                  </div>
                </template>
                <template v-slot:[`item.ActionD`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click.stop="checkDivisionsItem(item, 'EDIT')"><v-icon small v-text="'mdi-pencil'"></v-icon></v-btn>
                    </template>
                    <span>EDIT</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon :color="item.division_status == 'ACTIVE' ? 'red' : 'green'" @click.stop="checkDivisionsItem(item, 'ACTION')"
                        ><v-icon v-text="item.division_status == 'ACTIVE' ? 'mdi-close' : 'mdi-check'"></v-icon
                      ></v-btn>
                    </template>
                    <span v-text="item.division_status == 'ACTIVE' ? `Deactivate ${item.division_status}` : `Activate ${item.division_status}`"></span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.ActionA`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click.stop="checkDivisionsItem(item, 'EDIT')"><v-icon small v-text="'mdi-pencil'"></v-icon></v-btn>
                    </template>
                    <span>EDIT</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon :color="item.asset_status == 'ACTIVE' ? 'red' : 'green'" @click.stop="checkDivisionsItem(item, 'ACTION')"
                        ><v-icon small v-text="'mdi-delete'"></v-icon
                      ></v-btn>
                    </template>
                    <span v-text="`Delete ${item.asset_status}`"></span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.ActionDV`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click.stop="checkDivisionsItem(item, 'EDIT')"><v-icon small v-text="'mdi-pencil'"></v-icon></v-btn>
                    </template>
                    <span>EDIT</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click.stop="checkDivisionsItem(item, 'ACTION')"><v-icon small v-text="'mdi-delete'"></v-icon></v-btn>
                    </template>
                    <span v-text="`Delete ${item.device_status}`"></span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllAssetsAcrossOrganization } from "@/mixins/GetAllAssetsAcrossOrganization.js";
import { GetAllOrganizations } from "@/mixins/GetAllOrganizations.js";
import { GetAllDivisionsBasedOnOrganization } from "@/mixins/GetAllDivisionsBasedOnOrganization.js";
import { GetAllDevicesBasedOnOrganization } from "@/mixins/GetAllDevicesBasedOnOrganization.js";
import CreateDivisionsAssetDevicesDialog from "@/components/Divisions/Dialogs/CreateDivisionsAssetDevicesDialog.vue";
import ActivateInactivateAssetDialog from "@/components/Divisions/Dialogs/ActivateInactivateAssetDialog.vue";
export default {
  components: {
    Overlay,
    CreateDivisionsAssetDevicesDialog,
    ActivateInactivateAssetDialog,
  },
  mixins: [GetAllOrganizations, GetAllDivisionsBasedOnOrganization, GetAllAssetsAcrossOrganization, GetAllDevicesBasedOnOrganization],
  data: () => ({
    height: 0,
    search:"",
    noDataText: "",
    selectedItem: 0,
    StoreObj: {},
    StoreAllCommonObject: {},
    overlay: false,
    dialogCreateAssetDivisionAssetDevice: false,
    dialogActivateInactivateAsset: false,
    createDivisionAssetDevice: "Division",
    DivisionsHeaders: [
      { text: "Name", value: "division_name" },
      { text: "Created On", value: "division_created_on" },
      // { text: "Status", value: "division_status" },
      { text: "Action", value: "ActionD" },
    ],
    AssetHeaders: [
      { text: "Name", value: "asset_name" },
      { text: "Created On", value: "asset_created_on" },
      // { text: "Status", value: "asset_status" },
      { text: "Action", value: "ActionA" },
    ],
    DeviceHeaders: [
      { text: "Name", value: "device_name" },
      { text: "Created On", value: "device_created_on" },
      // { text: "Status", value: "device_status" },
      { text: "Action", value: "ActionDV" },
    ],
    current_view: "Asset",
    // "Division", -It was present inside the array currentViewItems
    currentViewItems: ["Asset", "Device"],
    GetAllDevicesBasedOnOrganizationList: [],
  }),
  watch: {
    GetAllOrganizationList(val) {
      if (val.length != 0) {
        this.StoreObj = val[0];
        this.GetAllAssetsAcrossOrganizationMethod(val[0].organization_id);
      }
    },
  },
  mounted() {
    this.selectedItem = 0;
    this.height = window.innerHeight - 140;
    this.GetAllOrganizationsMethod();
  },
  methods: {
    dialogActivateInactivateAssetEmit(Toggle) {
      this.dialogActivateInactivateAsset = false;
      if (Toggle == 2) {
        switch (this.current_view) {
          case "Asset":
            this.GetAllAssetsAcrossOrganizationMethod(this.StoreObj.organization_id);
            break;
          case "Device":
            this.GetAllDevicesBasedOnOrganizationMethod(this.StoreAllCommonObject.organization_id, this.StoreAllCommonObject.asset_id);
            break;
        }
      }
    },
    checkDivisionsItem(item, action) {
      this.StoreAllCommonObject = item;
      this.StoreAllCommonObject.action = action;
      switch (action) {
        case "EDIT":
          this.dialogCreateAssetDivisionAssetDevice = true;
          break;
        case "ACTION":
          this.dialogActivateInactivateAsset = true;
          break;
      }
    },
    CreateDivisionsAssetDevicesDialogEmit(Toggle) {
      this.dialogCreateAssetDivisionAssetDevice = false;
      if (Toggle == 2) {
        switch (this.current_view) {
          case "Asset":
            this.GetAllAssetsAcrossOrganizationMethod(this.StoreAllCommonObject.organization_id);
            break;
          case "Device":
            console.log("Mil Gaya Hain", this.StoreAllCommonObject);
            this.GetAllDevicesBasedOnOrganizationMethod(this.StoreAllCommonObject.organization_id, this.StoreAllCommonObject.asset_id);
            break;
        }
      }
    },
    checkRowItem(item, action) {
      this.StoreAllCommonObject = item;
      if (this.currentViewItems.indexOf(this.current_view) < this.currentViewItems.length) {
        this.current_view =
          typeof action == "object" && !item.device_id
            ? this.currentViewItems[this.currentViewItems.indexOf(this.current_view) + 1]
            : typeof action == "string"
            ? this.currentViewItems[this.currentViewItems.indexOf(this.current_view) - 1]
            : "Device";
        switch (this.current_view) {
          case "Asset":
            this.GetAllAssetsAcrossOrganizationMethod(item.organization_id);
            break;
          case "Device":
            this.GetAllDevicesBasedOnOrganizationMethod(item.organization_id, item.asset_id);
            break;
        }
      }
    },
    checkItem(item) {
      this.StoreObj = item;
      this.StoreAllCommonObject = item;
      this.current_view = "Asset";
      this.GetAllAssetsAcrossOrganizationMethod(this.StoreObj.organization_id);
    },
  },
};
</script>
