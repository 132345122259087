<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogCreateAssetDivisionAssetDevice">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Create {{ current_view }}</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createDivisionAssetDeviceEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4">
            <v-text-field
              dense
              outlined
              :label="`${current_view} Name`"
              class="field_height field_label_size FontSize"
              :rules="[(v) => !!v || 'Required']"
              v-model="asset_division_device_name"
            ></v-text-field>
            <v-text-field
              dense
              v-if="current_view == 'Asset' || current_view == 'Device'"
              outlined
              :label="`${current_view} ID`"
              class="field_height field_label_size FontSize"
              :rules="[(v) => !!v || 'Required']"
              v-model="asset_device_id"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius" outlined color="primary" @click="createDivisionAssetDeviceEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createDivision, updateDivision, createAsset, updateAsset, createDevice,updateDevice } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogCreateAssetDivisionAssetDevice: Boolean,
    StoreAllCommonObject: Object,
    current_view: String,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    Asset_type: "",
    AssetTypeItems: [
      { text: "Admin", value: "ADMIN" },
      { text: "Super Admin", value: "SUPER_ADMIN" },
    ],
    loading: false,
    asset_name: "",
    asset_division_device_name: "",
    asset_device_id: "",
    SnackBarComponent: {},
  }),
  watch: {
    dialogCreateAssetDivisionAssetDevice(val) {
      if (val == true) {
        console.log("Check StoreAllCommonObject", this.StoreAllCommonObject);
        console.log("Check Current View", this.current_view);
        if (this.StoreAllCommonObject.action == "EDIT") {
          switch (this.current_view) {
            case "Division":
              this.asset_division_device_name = this.StoreAllCommonObject.division_name;
              break;
            case "Asset":
              this.asset_division_device_name = this.StoreAllCommonObject.asset_name;
              this.asset_device_id = this.StoreAllCommonObject.asset_type;
              break;
            case "Device":
              this.asset_division_device_name = this.StoreAllCommonObject.device_name;
              this.asset_device_id = this.StoreAllCommonObject.device_serial_number;
              break;
          }
        }
      }
    },
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.createDivisionAssetDevice();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async createDivisionAssetDevice() {
      console.log("Hello",this.current_view)
      var mutationname = null;
      var mutationresult = null;
      switch (this.current_view) {
        case "Division":
          switch (this.StoreAllCommonObject.action) {
            case "CREATE":
              mutationname = createDivision;
              mutationresult = "CreateDivision";
              var inputParams = {
                division_name: this.asset_division_device_name,
                organization_id: this.StoreObj.organization_id,
              };
              break;
            case "EDIT":
              inputParams = {
                division_name: this.asset_division_device_name,
                division_id: this.StoreAllCommonObject.division_id,
                organization_id: this.StoreObj.organization_id,
              };
              mutationname = updateDivision;
              mutationresult = "UpdateDivision";
              break;
          }
          break;
        case "Asset":
          console.log("Check Re", this.StoreAllCommonObject);
          switch (this.StoreAllCommonObject.action) {
            case "CREATE":
              mutationname = createAsset;
              mutationresult = "CreateAsset";
              inputParams = {
                asset_name: this.asset_division_device_name,
                asset_type: this.asset_device_id,
                organization_id: this.StoreObj.organization_id,
              };
              break;
            case "EDIT":
              inputParams = {
                asset_name: this.asset_division_device_name,
                asset_type: this.asset_device_id,
                asset_id: this.StoreAllCommonObject.asset_id,
                organization_id: this.StoreObj.organization_id,
              };
              mutationname = updateAsset;
              mutationresult = "UpdateAsset";
              break;
          }
          break;
        case "Device":
          switch (this.StoreAllCommonObject.action) {
            case "CREATE":
              mutationname = createDevice;
              mutationresult = "CreateDevice";
              inputParams = {
                device_name: this.asset_division_device_name,
                device_serial_number: this.asset_device_id,
                asset_id: this.StoreAllCommonObject.asset_id,
                organization_id: this.StoreObj.organization_id,
              };
              break;
            case "EDIT":
              inputParams = {
                device_id: this.StoreAllCommonObject.device_id,
                device_name: this.asset_division_device_name,
                device_serial_number: this.asset_device_id,
                asset_id: this.StoreAllCommonObject.asset_id,
                organization_id: this.StoreObj.organization_id,
              };
              mutationname = updateDevice;
              mutationresult = "UpdateDevice";
              break;
          }
          break;
      }
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.createDivisionAssetDeviceEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log("Check The Error",error)
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    createDivisionAssetDeviceEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
