import { listDevices } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllDevicesBasedOnOrganization = {
  data() {
    return {
      overlay: false,
      GetAllDevicesBasedOnOrganizationList: [],
    };
  },
  methods: {
    async GetAllDevicesBasedOnOrganizationMethod(organization_id, asset_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listDevices, {
            input: {
              organization_id: organization_id,
              asset_id: asset_id,
            },
          })
        );
        this.GetAllDevicesBasedOnOrganizationList = JSON.parse(result.data.ListDevices).data.items;
        this.overlay = false;
      } catch (error) {
        this.GetAllDevicesBasedOnOrganizationList = [];
        this.noDataText = error.errors[0].message;
        this.overlay = false;
      }
    },
  },
};
