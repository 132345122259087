<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogActivateInactivateAsset" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div>
            <span
              v-text="StoreAllCommonObject.division_status == 'ACTIVE' || StoreAllCommonObject.asset_status == 'ACTIVE' || StoreAllCommonObject.device_status == 'ACTIVE' ? 'Deactivate' : 'Activate'"
            ></span>
            Asset
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogActivateInactivateAssetEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-icon size="50px" :color="'red'" v-text="'mdi-delete'"></v-icon>
          <div>
            Are you sure you want to <span v-text="'delete'"></span>
            {{
              StoreAllCommonObject.division_name
                ? StoreAllCommonObject.division_name
                : StoreAllCommonObject.asset_name
                ? StoreAllCommonObject.asset_name
                : StoreAllCommonObject.device_name
                ? StoreAllCommonObject.device_name
                : ""
            }}
            ?
          </div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogActivateInactivateAssetEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="activateInactivateMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { deleteAsset, deleteDevice } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogActivateInactivateAsset: Boolean,
    StoreAllCommonObject: Object,
    current_view: String,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    add_issuer: {
      user_name: "",
      user_email_id: "",
    },
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {},
  methods: {
    async activateInactivateMethod() {
      var mutationname = null;
      var mutationresult = null;
      console.log("current_viewcurrent_view",this.current_view)
      try {
        var inputParams = {};
        switch (this.current_view) {
          case "Asset":
            mutationname = deleteAsset;
            mutationresult = "DeleteAsset";
            inputParams.asset_id = this.StoreAllCommonObject.asset_id;
            break;
          case "Device":
            mutationname = deleteDevice;
            mutationresult = "DeleteDevice";
            // inputParams.organization_id = this.StoreAllCommonObject.organization_id;
            // inputParams.division_id = this.StoreAllCommonObject.division_id;
            // inputParams.asset_id = this.StoreAllCommonObject.asset_id;
            inputParams.device_id = this.StoreAllCommonObject.device_id;
            break;
        }
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogActivateInactivateAssetEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log("Getting Error", error);
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogActivateInactivateAssetEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
