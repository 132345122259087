import { listOrganizations } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllOrganizations = {
  data() {
    return {
      overlay: false,
      GetAllOrganizationList: [],
    };
  },
  methods: {
    async GetAllOrganizationsMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listOrganizations, {
            input: {},
          })
        );
        this.GetAllOrganizationList = JSON.parse(result.data.ListOrganizations).data.items;
        this.overlay = false;
      } catch (error) {
        this.noDataText = error.errors[0].message;
        this.overlay = false;
      }
    },
  },
};
